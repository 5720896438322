"use client";
import { getCookie } from "cookies-next";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";

if (typeof window !== "undefined") {
  const flags = getCookie("bootstrapData");

  let bootstrapData: any = {};
  if (flags) {
    bootstrapData = JSON.parse(flags);
  }

  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY || "", {
    api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST,
    capture_pageview: false,
    // Uncomment to disable session recordings in the local development environment
    // disable_session_recordings: process.env.NODE_ENV === "development",
    bootstrap: bootstrapData,
  });
}

export function CSPostHogProvider({ children }: { children: React.ReactNode }) {
  return <PostHogProvider client={posthog}>{children}</PostHogProvider>;
}
