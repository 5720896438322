type TimeStamp = string;

interface ApiKey {
  created_by: string;
  created_by_org_id: string;
  expires_at: TimeStamp;
  id: string;
}

export interface DomainData {
  apiKeys: ApiKey[];
  enableBilling: boolean;
  favicon: string;
  giTenantId: string;
  logo: string;
  mainDescription: string;
  orgId: string;
  orgName: string;
  subDescription: string;
  title: string;
  [key: string]: unknown;
}

export function isDomainData(data: unknown): data is DomainData {
  const domainData = data as DomainData;

  return (
    typeof domainData === "object" &&
    typeof domainData.apiKeys === "object" &&
    Array.isArray(domainData.apiKeys) &&
    typeof domainData.enableBilling === "boolean" &&
    typeof domainData.favicon === "string" &&
    typeof domainData.giTenantId === "string" &&
    typeof domainData.logo === "string" &&
    typeof domainData.mainDescription === "string" &&
    typeof domainData.orgId === "string" &&
    typeof domainData.orgName === "string" &&
    typeof domainData.subDescription === "string" &&
    typeof domainData.title === "string"
  );
}

export const DEFAULT_DOMAIN_DATA = {
  apiKeys: [],
  enableBilling: false,
  favicon: "",
  giTenantId: "",
  logo: "",
  mainDescription: "",
  orgId: "",
  orgName: "",
  subDescription: "",
  title: "",
};
