import(/* webpackMode: "eager", webpackExports: ["Favicon"] */ "/vercel/path0/apps/coach-center/shared/components/Favicon/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/coach-center/shared/components/PostHogPageView/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CSPostHogProvider"] */ "/vercel/path0/apps/coach-center/shared/components/PosthogProvider/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AuthProvider"] */ "/vercel/path0/apps/coach-center/shared/lib/providers/authProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/coach-center/shared/lib/store/StoreProvider.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/coach-center/shared/styles/custom.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/coach-center/shared/styles/globals.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.13_@babel+core@7.24.5_babel-plugin-macros@3.1.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.13_@babel+core@7.24.5_babel-plugin-macros@3.1.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.13_@babel+core@7.24.5_babel-plugin-macros@3.1.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/sonner@1.5.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/sonner/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/ui/dist/index.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.13_@babel+core@7.24.5_babel-plugin-macros@3.1.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/font/local/target.css?{\"path\":\"app/fonts.ts\",\"import\":\"\",\"arguments\":[{\"variable\":\"--font-opensauce-sans\",\"src\":[{\"path\":\"../public/fonts/OpenSauceTwo-Light.ttf\",\"weight\":\"300\",\"style\":\"normal\"},{\"path\":\"../public/fonts/OpenSauceTwo-LightItalic.ttf\",\"weight\":\"300\",\"style\":\"italic\"},{\"path\":\"../public/fonts/OpenSauceTwo-Regular.ttf\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"../public/fonts/OpenSauceTwo-Italic.ttf\",\"weight\":\"400\",\"style\":\"italic\"},{\"path\":\"../public/fonts/OpenSauceTwo-Medium.ttf\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"../public/fonts/OpenSauceTwo-MediumItalic.ttf\",\"weight\":\"500\",\"style\":\"italic\"},{\"path\":\"../public/fonts/OpenSauceTwo-SemiBold.ttf\",\"weight\":\"600\",\"style\":\"normal\"},{\"path\":\"../public/fonts/OpenSauceTwo-SemiBoldItalic.ttf\",\"weight\":\"600\",\"style\":\"italic\"},{\"path\":\"../public/fonts/OpenSauceTwo-Bold.ttf\",\"weight\":\"700\",\"style\":\"normal\"},{\"path\":\"../public/fonts/OpenSauceTwo-BoldItalic.ttf\",\"weight\":\"700\",\"style\":\"italic\"},{\"path\":\"../public/fonts/OpenSauceTwo-ExtraBold.ttf\",\"weight\":\"800\",\"style\":\"normal\"},{\"path\":\"../public/fonts/OpenSauceTwo-ExtraBoldItalic.ttf\",\"weight\":\"800\",\"style\":\"italic\"},{\"path\":\"../public/fonts/OpenSauceTwo-Black.ttf\",\"weight\":\"900\",\"style\":\"normal\"},{\"path\":\"../public/fonts/OpenSauceTwo-BlackItalic.ttf\",\"weight\":\"900\",\"style\":\"italic\"}]}],\"variableName\":\"openSauceSans\"}");
